import * as L from 'leaflet';
// import 'leaflet.markercluster';




export class Nsmap {

    get maCarte(): any {
        return this._maCarte;
    }

    set maCarte(value: any) {
        this._maCarte = value;
    }
    private _maCarte: L.Map;

    constructor(lat:any, lon:any ,options:any, element:HTMLElement) {

        this.initMap(lat, lon ,options, element);

    }
    initMap = (lat:any, lon:any ,options:any, element:HTMLElement) => {

        let zoom = 9;
        if(options.hasOwnProperty('zoom')) {
            zoom = options.zoom;
        }
        let maCarte = L.map(element).setView([lat, lon], zoom);
        maCarte.invalidateSize();

        maCarte.scrollWheelZoom.disable();
        maCarte.once('focus', function() { maCarte.scrollWheelZoom.enable(); });
        let enableScrollView = options.hasOwnProperty("enableScrollView") && ! options.enableScrollView;

        if(options.hasOwnProperty('polygon')) {

            let geojsonFeature: any = {
                "type": "Feature",
                "properties": {
                    "name": "Coors Field",
                    "amenity": "Baseball Stadium",
                    "popupContent": "This is where the Rockies play!"
                },
                "geometry": options.polygon
            };
            L.geoJSON(geojsonFeature).addTo(maCarte);

        }

        if(options.hasOwnProperty("markers")) {
            for (let i = 0; i <  options.markers.length; i++) {
                let marker = options.markers[i];
                let icon = L.icon({
                    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.4.0/images/marker-icon.png',
                    iconSize: [24,36],
                    iconAnchor: [12,36],
                    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.4.0/images/marker-shadow.png',
                });



                if(options.hasOwnProperty("disablePopup")) {
                    if(options.disablePopup == true) {
                        L.marker([marker.lat, marker.long], {icon: icon}).addTo(maCarte)

                    } else {
                      L.marker([marker.lat, marker.long], {icon: icon}).addTo(maCarte).bindPopup(marker.title)

                    }
                } else {
                    L.marker([marker.lat, marker.long], {icon: icon}).addTo(maCarte).bindPopup(marker.title)

                }


            }
        }

        if(!enableScrollView) {
            maCarte.on('click', function() {
                if (maCarte.scrollWheelZoom.enabled()) {
                    maCarte.scrollWheelZoom.disable();
                }
                else {
                    maCarte.scrollWheelZoom.enable();
                }
            });
        }
        const tabServer = "abc";
        let prefixServer = this.generateRandSeq(1,tabServer);
        // Leaflet ne récupère pas les cartes (tiles) sur un serveur par défaut. Nous devons lui préciser où nous souhaitons les récupérer. Ici, openstreetmap.fr
        L.tileLayer('https://'+prefixServer+'.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            // Il est toujours bien de laisser le lien vers la source des données
            attribution: 'données © <a href="//osm.org/copyright">OpenStreetMap</a>/ODbL - rendu <a href="//openstreetmap.fr">OSM France</a>',
            minZoom: 1,
            maxZoom: 20
        }).addTo(maCarte);

        this._maCarte = maCarte;


    }
    randomInt(low: number, high: number)
    {
        return Math.floor(Math.random()*(high-low+1)) + low;
    }
    generateRandSeq(len: number, letters: String)

    {
        var i, seq = "";
        for (i = 1; i <= len; i = i + 1) {
            seq += this.randomChar(letters);
        }
        return seq;
    }

    randomChar(str: String)

    {
        return str.charAt(this.randomInt(0, str.length-1));
    }

    addMarker = (markers: object[]) => {
        for (let i = 0; i <  markers.length; i++) {
            let marker = markers[i];
            let leMarker = JSON.stringify(marker);
            let leMarkerOk = JSON.parse(leMarker);
            let icon = L.icon({
                iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.4.0/images/marker-icon.png',
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.4.0/images/marker-shadow.png',
                iconSize: [24,36],
                iconAnchor: [12,36],
            });
            L.marker([leMarkerOk.lat, leMarkerOk.long], {icon: icon}).addTo(this.maCarte).bindPopup(leMarkerOk.title);
         }
    }




}




Object.defineProperty(Element.prototype, 'nsmap', { value: function(lat:any, lon:any ,options:any) {
        let element = this;
        let nsmap = new Nsmap(lat, lon, options, element);

        return nsmap;

    }, enumerable: true
});

document.querySelector('head').innerHTML += '<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.4.0/leaflet.css"/>';




